import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import { currentServerConfiguration } from '@s/server-configuration';

import './style/bootstrap-custom.scss';
import 'vue-select/dist/vue-select.css';
import './style/shared.scss';

import vueDebounce from 'vue-debounce';
import vSelect from 'vue-select';
import { Providers, SimpleProvider } from '@microsoft/mgt';
import { authModule } from '@t/session';
import { ajaxCall } from '@t/ajax-wrapper';
import Vuelidate from 'vuelidate';
import VueEllipseProgress from 'vue-ellipse-progress';

import './validation';

Vue.component('v-select', vSelect);
Vue.use(vueDebounce);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(VueEllipseProgress);

export interface IDictionary<T> {
    [index: string]: T;
}

Vue.directive('click-outside', {
    bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event: any) {
            const ignoreClass = Object.keys(binding.modifiers);
            // Ignore items with a certain class
            const path = event.path || (event.composedPath && event.composedPath());
            const containsIgnoredClass =
                path && ignoreClass.some(c => path.some((p: any) => p.classList && p.classList.contains(c)));
            if (
                !containsIgnoredClass &&
                !(el === event.target || el.contains(event.target)) &&
                vnode.context &&
                (!path || path.indexOf(el) === -1)
            ) {
                ((vnode.context as unknown) as IDictionary<any>)[binding.expression!](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function(el) {
        if (el.clickOutsideEvent) {
            document.body.removeEventListener('click', el.clickOutsideEvent);
        }
    }
});

currentServerConfiguration.loadConfiguration().then(async success => {
    Vue.config.productionTip = false;

    await authModule.loadAuthModuleConfiguration();
    Providers.globalProvider = new SimpleProvider(
        async (_: string[]) => await authModule.getAccessToken(),
        async () => await authModule.login(),
        async () => await authModule.logoutAsync()
    );

    await ajaxCall.initWebStore();

    if (success === true) {
        const loadApp = await import('./App.vue');

        const router = await import('./router');
        // const validation = await import('./validation');

        new Vue({
            render: h => h(loadApp.default),
            router: router.default
        }).$mount('#app');
    }
});

declare global {
    // eslint-disable-next-line
    interface HTMLElement {
        clickOutsideEvent?: (event: any) => void;
    }
}
