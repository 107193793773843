/* eslint-disable no-console */
import { currentServerConfiguration } from '@s/server-configuration';
import {
    PublicClientApplication,
    SilentRequest,
    AuthenticationResult,
    Configuration,
    LogLevel,
    AccountInfo,
    InteractionRequiredAuthError,
    RedirectRequest,
    EndSessionRequest,
    BrowserCacheLocation
} from '@azure/msal-browser';
import { Providers, ProviderState } from '@microsoft/mgt';
import { AuthenticationProvider } from '@microsoft/microsoft-graph-client';

const MSAL_CONFIG: Configuration = {
    auth: {
        clientId: '',
        authority: ''
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Session for testing ? localstorage when deploy ??
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                    //    return;
                    // case LogLevel.Info:
                    //     console.info(message);
                    //     return;
                    // case LogLevel.Verbose:
                    //     console.debug(message);
                    //     return;
                    // case LogLevel.Warning:
                    //     console.warn(message);
                }
            }
        }
    }
};

class AuthModule implements AuthenticationProvider {
    private myMSALObj!: PublicClientApplication; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/classes/_src_app_publicclientapplication_.publicclientapplication.html
    private account: AccountInfo | undefined; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html
    private loginRedirectRequest!: RedirectRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_popuprequest_.html
    private loginRequest!: SilentRequest;

    public loadAuthModuleConfiguration(): Promise<void> {
        const config = currentServerConfiguration.getConfiguration();
        MSAL_CONFIG.auth.clientId = config.clientId;
        MSAL_CONFIG.auth.authority = config.authority;
        MSAL_CONFIG.auth.redirectUri = config.redirectUri;
        this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
        this.setRequestObjects();

        return this.loadAuthModule();
    }

    /**
     * Initialize request objects used by this AuthModule.
     */
    private setRequestObjects(): void {
        this.loginRedirectRequest = {
            scopes: [
                'openid',
                'profile',
                'User.Read',
                'User.ReadBasic.All',
                'User.Read.All',
                'Group.Read.All',
                'People.Read',
                'People.Read.All',
                'AppRoleAssignment.ReadWrite.All',
                'Application.Read.All',
                'email'
            ],
            redirectStartPage: window.location.href
        };
        this.loginRequest = {
            scopes: [
                'openid',
                'profile',
                'User.Read',
                'User.ReadBasic.All',
                'User.Read.All',
                'Group.Read.All',
                'People.Read',
                'People.Read.All',
                'AppRoleAssignment.ReadWrite.All',
                'Application.Read.All',
                'email'
            ]
        };
    }

    // TODO: Suppression de la localeDb pour l'auth, sinon bug affichage changement de compte
    // Soit bypass, soit trigger de suppression du fichier dans => chrome://settings/cookies/detail?site=localhost&search=cookie
    // Suppression "Stockage des bases de données"
    public getAccount(): AccountInfo | null {
        // need to call getAccount here?
        if (this.myMSALObj) {
            const currentAccounts = this.myMSALObj.getAllAccounts();
            if (currentAccounts === null) {
                return null;
            }

            if (currentAccounts.length >= 1) {
                Providers.globalProvider.setState(ProviderState.SignedIn);
                return currentAccounts[0];
            }
        }
        return null;
    }

    private handleResponse(response: AuthenticationResult): void {
        if (response && response.account) {
            this.account = response.account;
        } else {
            const acc = this.getAccount();
            if (acc != null) {
                this.account = acc;
            }
        }
    }

    public loadAuthModule(): Promise<void> {
        return this.myMSALObj
            .handleRedirectPromise()
            .then((resp: AuthenticationResult | null) => {
                if (resp) this.handleResponse(resp);
            })
            .catch(console.error);
    }

    public async login(): Promise<void> {
        if (this.account === undefined) await this.myMSALObj.loginRedirect(this.loginRedirectRequest);
    }

    /**
     * Logs out of current account.
     */
    public logout(): void {
        const logOutRequest: EndSessionRequest = {
            account: this.account
        };

        this.myMSALObj.logout(logOutRequest);
    }

    /**
     * Logs out of current account.
     */
    public async logoutAsync(): Promise<void> {
        const logOutRequest: EndSessionRequest = {
            account: this.account
        };

        await this.myMSALObj.logout(logOutRequest);
    }

    /**
     * Gets the token to read mail data from MS Graph silently, or falls back to interactive redirect.
     */
    public async getAccessToken(): Promise<string> {
        this.loginRequest.account = this.account;
        const res = await this.getTokenRedirect(this.loginRequest, this.loginRedirectRequest);
        if (res !== undefined) {
            return res.accessToken;
        }
        return '';
    }

    public async getIdToken(): Promise<string> {
        this.loginRequest.account = this.account;
        const res = await this.getTokenRedirect(this.loginRequest, this.loginRedirectRequest);
        if (res !== undefined) {
            return res.idToken;
        }
        return '';
    }

     /**
     * Refresh the idToken 5 min before expiration
     */
    private refreshIdToken(silentRequest){

        const idTokenExp = Number(silentRequest.account.idTokenClaims["exp"]);
        const secondsSinceEpoch = Math.round(Date.now() / 1000)
        if( idTokenExp - secondsSinceEpoch < 5 * 60 ){
            silentRequest.forceRefresh = true
        }
    }

    /**
     * Gets a token silently, or falls back to interactive redirect.
     */
    private async getTokenRedirect(
        silentRequest: SilentRequest,
        interactiveRequest: RedirectRequest
    ): Promise<AuthenticationResult | undefined> {
        try {
            if (!silentRequest.account) {
                const acc = this.getAccount();
                if (acc != null) silentRequest.account = acc;
                this.refreshIdToken(silentRequest);
            }
            const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
            return response;
        } catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
                this.myMSALObj.acquireTokenRedirect(interactiveRequest).catch(console.error);
            } else {
                console.error(e);
            }
        }
    }
}
export const authModule: AuthModule = new AuthModule();
