export interface IGroupNamingConvention {
    prefix: string;
    separator: string;
}

export interface IServerConfiguration {
    clientId: string;
    authority: string;
    baseUrl: string;
    aiKey: string;
    redirectUri: string;
    apiUrl: string;
    groupNamingConvention: IGroupNamingConvention;
    pRoleId: string;
}

class CurrentServerConfiguration {
    private current: IServerConfiguration = {} as IServerConfiguration;

    public async loadConfiguration(): Promise<boolean> {
        if (process.env.NODE_ENV === 'production') {
            return await fetch('#{BaseUrl}#/config.json')
                .then(async conf => (await conf.json()) as IServerConfiguration)
                .then(confLoaded => this.storeConfiguration(confLoaded));
        } else {
            return new Promise(resolve =>
                resolve(
                    this.storeConfiguration({
                        clientId: 'fddef31b-34c8-454f-819b-d9b46e15b70d',
                        authority: 'https://login.microsoftonline.com/41011335-ab5f-4019-9d22-4c44d8b1b52f/',
                        baseUrl: 'http://localhost:4200',
                        aiKey: '',
                        redirectUri: 'http://localhost:4200',
                        apiUrl: 'https://localhost:44325',
                        groupNamingConvention: {
                            prefix: 'Ind',
                            separator: '.'
                        },
                        pRoleId: 'e8611ab8-c189-46e8-94e1-60213ab1f814'
                    } as IServerConfiguration)
                )
            );
        }
    }

    public storeConfiguration(cfg: IServerConfiguration): boolean {
        this.current = cfg;
        return true;
    }

    public getConfiguration() {
        return this.current;
    }
}

export const currentServerConfiguration = new CurrentServerConfiguration();
