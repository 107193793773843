import { currentServerConfiguration } from '@s/server-configuration';
import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource, ResponseType as AxiosResponseType } from 'axios';
import { authModule } from './session';

export interface IApiError {
    message?: string;
    code?: number;
}
export interface ICancellableResult<R> {
    cancelled: boolean;
    error?: IApiError;
    datas: R | null;
}
export interface IParameteredAjaxCallType {
    post: string;
    put: string;
    patch: string;
}
export interface IAjaxCallType {
    get: string;
    delete: string;
}

export interface IBaseAjaxCallParam<P> {
    payload?: P | null;
    headers?: Record<string, any> | null;
    canBeCancelled?: boolean;
    responseType?: AxiosResponseType;
    cancelUrl?: string | null;
    queryParameter?: Record<string, string> | null;
    urlParameter?: string[] | null;
}

export function isCallValidAndNotCancelled<T>(call: ICancellableResult<T> | null): boolean {
    return call != null && call.cancelled === false && !call.error && call.datas != null;
}
class AjaxCall {
    private cancellableObject: { [key: string]: CancelTokenSource } = {};

    private async cancelGenericPreviousRequest(url: string) {
        if (url != null) {
            const tokenHandler = this.cancellableObject[url];
            if (tokenHandler != null) {
                tokenHandler.cancel('Cancelled');
            }
        }
    }

    private async addNewGenericCancellableToken(url: string, tokenSource: CancelTokenSource) {
        this.cancellableObject[url] = tokenSource;
    }

    private async requestInterceptor(config: AxiosRequestConfig) {
        const token = await authModule.getIdToken();
        config.headers = {
            Authorization: 'Bearer ' + token,
            GraphToken: await authModule.getAccessToken()
        };
        return config;
    }

    private createConfig(source: CancelTokenSource | undefined, headers: Record<string, any> | null | undefined, responseType?: AxiosResponseType) {
        const config: AxiosRequestConfig = {
            cancelToken: source != null ? source.token : undefined,
            headers,
            responseType
        };
        return config;
    }

    private async callWithParameter<P = null>(
        url: string,
        source: CancelTokenSource | undefined,
        type: keyof IParameteredAjaxCallType,
        headers: Record<string, any> | null | undefined,
        parameter: P | null = null,
        responseType?: AxiosResponseType
    ): Promise<AxiosResponse<any>> {
        const config = this.createConfig(source, headers, responseType);
        return axios[type](url, parameter, config);
    }

    private async callWithoutParameter(
        url: string,
        source: CancelTokenSource | undefined,
        type: keyof IAjaxCallType,
        headers: Record<string, any> | null | undefined,
        responseType?: AxiosResponseType
    ): Promise<AxiosResponse<any>> {
        const config = this.createConfig(source, headers, responseType);
        return axios[type](url, config);
    }

    private enrichAndCleanUrl(url: string, queryParams?: Record<string, string> | null, urlParam?: string[] | null): string {
        let newUri = url;
        const qParamString = queryParams ? '?' + new URLSearchParams(queryParams).toString() : '';
        if (urlParam && urlParam.length > 0) {
            const uParamString = urlParam.join('/');
            newUri = `${newUri}/${uParamString}`;
        }
        newUri = `${newUri}${qParamString}`;
        if (newUri.indexOf('https://') === 0) {
            newUri = 'https://' + newUri.replace('https://', '').replaceAll('//', '/');
        } else if (newUri.indexOf('http://') === 0) {
            newUri = 'http://' + newUri.replace('http://', '').replaceAll('//', '/');
        } else {
            newUri = newUri.replaceAll('//', '/');
        }
        return newUri;
    }

    public async baseAjaxCall<R, P = null>(
        type: keyof IAjaxCallType | keyof IParameteredAjaxCallType = 'post',
        url: string,
        params: IBaseAjaxCallParam<P> = {
            headers: null,
            payload: null,
            canBeCancelled: false
        }
    ): Promise<ICancellableResult<R>> {
        let source: CancelTokenSource | undefined;
        if (params.canBeCancelled) {
            await this.cancelGenericPreviousRequest(params.cancelUrl ?? url);
            const CancelToken = axios.CancelToken;
            source = CancelToken.source();
            await this.addNewGenericCancellableToken(params.cancelUrl ?? url, source);
        }
        const returnValue = { cancelled: false, datas: null } as ICancellableResult<R>;
        try {
            let response: AxiosResponse | null = null;
            url = this.enrichAndCleanUrl(url, params.queryParameter, params.urlParameter);
            switch (type) {
                case 'get':
                case 'delete':
                    response = await this.callWithoutParameter(url, source, type, params.headers, params.responseType);
                    break;
                case 'post':
                case 'put':
                case 'patch':
                    response = await this.callWithParameter<P>(url, source, type, params.headers, params.payload, params.responseType);
                    break;
            }
            if (response && response.data != null) {
                returnValue.datas = response.data;
            }
        } catch (error) {
            if (error.message === 'Cancelled') {
                returnValue.cancelled = true;
            } else if (error.response && error.response.data) {
                returnValue.error = error.response.data;
            }
        }
        return returnValue;
    }

    /**
     * Basic Get
     * @param url     url to get
     * @param queryParams  parameter in query string
     */
    public get<R>(url: string, queryParams?: Record<string, string> | null): Promise<ICancellableResult<R>> {
        return this.baseAjaxCall<R>('get', url, { queryParameter: queryParams !== undefined ? queryParams : null });
    }

    /**
     * Basic post
     * @param url     url to post
     * @param body    data you need to put in the body
     * @param queryParams  parameter in query string
     */
    public post<R, P = null>(url: string, body?: P, queryParams?: Record<string, string> | null): Promise<ICancellableResult<R>> {
        return this.baseAjaxCall<R, P>('post', url, { payload: body !== undefined ? body : null, queryParameter: queryParams !== undefined ? queryParams : null });
    }

    public patch<R, P = null>(url: string, body?: P | null, queryParams?: Record<string, string> | null): Promise<ICancellableResult<R>> {
        return this.baseAjaxCall<R, P>('patch', url, { payload: body !== undefined ? body : null, queryParameter: queryParams !== undefined ? queryParams : null });
    }

    public delete<R, P = null>(url: string, body?: P | null, queryParams?: Record<string, string> | null): Promise<ICancellableResult<R>> {
        return this.baseAjaxCall<R, P>('delete', url, { payload: body !== undefined ? body : null, queryParameter: queryParams !== undefined ? queryParams : null });
    }

    public async initWebStore() {
        const cfg = currentServerConfiguration.getConfiguration();
        axios.defaults.baseURL = cfg.apiUrl;
        axios.interceptors.request.use(this.requestInterceptor);
    }
}

export const ajaxCall = new AjaxCall();
